
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "2px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Trainee Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Father Name",
          key: "father_name",
          sortable: true,
        },
        {
          name: "Mother Name",
          key: "mother_name",
          sortable: true,
        },
        {
          name: "License No",
          key: "license_no",
          sortable: true,
        },
        {
          name: "Application Date",
          key: "application_date",
          sortable: true,
        },
        {
          name: "Given Date",
          key: "given_date",
          sortable: true,
        },
        {
          name: "Expiry Date",
          key: "expiry_date",
          sortable: true,
        },
      ],
      tableData: [
        {
          id: 1,
          photo: "",
          ref_no: "T3190201",
          reg_no: "4200000021",
          name: "Md. Rashel Kobir",
          father_name: "ফজলুর রহমান ",
          mother_name: "মোছাঃ রাবেয়া খাতুন ",
          license_no: "",
          application_date: "",
          given_date: "",
          expiry_date: "",
        },
        {
          id: 2,
          photo: "",
          ref_no: "T3190202",
          reg_no: "4200000022",
          name: "Md Masum Rana ",
          father_name: "মোঃ আফজাল হোসেন",
          mother_name: "মোছাঃ মাজেদা খাতুন ",
          license_no: "",
          application_date: "",
          given_date: "",
          expiry_date: "",
        },
        {
          id: 3,
          photo: "",
          ref_no: "T3190203",
          reg_no: "4200000023",
          name: "Md. Mayn Uddin ",
          father_name: "রুহুল আমিন ",
          mother_name: "তারামনন্নেছা",
          license_no: "",
          application_date: "",
          given_date: "",
          expiry_date: "",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
